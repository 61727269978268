<template>
    <footer class="footer-view">
        <div class="footer-left">
            <svg
                class="logo-svg"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                viewBox="0 0 338.62 475.73"
            >
                <defs>
                    <linearGradient
                        id="Nepojmenovaný_přechod_83"
                        x1="494.93"
                        y1="242.91"
                        x2="744.82"
                        y2="242.91"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset="0" stop-color="#04374f" />
                        <stop offset="0.11" stop-color="#043e58" />
                        <stop offset="0.3" stop-color="#045272" />
                        <stop offset="0.53" stop-color="#03739b" />
                        <stop offset="0.55" stop-color="#03769f" />
                        <stop offset="0.99" stop-color="#04374f" />
                    </linearGradient>
                    <linearGradient
                        id="Nepojmenovaný_přechod_83-2"
                        x1="750.34"
                        y1="259.6"
                        x2="750.34"
                        y2="259.6"
                        xlink:href="#Nepojmenovaný_přechod_83"
                    />
                    <linearGradient
                        id="Nepojmenovaný_přechod_82"
                        x1="784.15"
                        y1="394.57"
                        x2="765"
                        y2="266.66"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset="0" stop-color="#04374f" />
                        <stop offset="0.2" stop-color="#043e58" />
                        <stop offset="0.54" stop-color="#045272" />
                        <stop offset="0.96" stop-color="#03739b" />
                        <stop offset="1" stop-color="#03769f" />
                    </linearGradient>
                    <linearGradient
                        id="Nepojmenovaný_přechod_86"
                        x1="477.68"
                        y1="339.1"
                        x2="814.23"
                        y2="339.1"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset="0" stop-color="#03769f" />
                        <stop offset="1" stop-color="#04374f" />
                    </linearGradient>
                    <linearGradient
                        id="Nepojmenovaný_přechod_83-3"
                        x1="725.87"
                        y1="176.02"
                        x2="725.87"
                        y2="176.02"
                        xlink:href="#Nepojmenovaný_přechod_83"
                    />
                    <linearGradient
                        id="Nepojmenovaný_přechod_83-4"
                        x1="444.85"
                        y1="219.31"
                        x2="694.75"
                        y2="219.31"
                        gradientTransform="translate(1243.91 779.35) rotate(180)"
                        xlink:href="#Nepojmenovaný_přechod_83"
                    />
                    <linearGradient
                        id="Nepojmenovaný_přechod_83-5"
                        x1="700.26"
                        y1="236"
                        x2="700.26"
                        y2="236"
                        gradientTransform="translate(1243.91 779.35) rotate(180)"
                        xlink:href="#Nepojmenovaný_přechod_83"
                    />
                    <linearGradient
                        id="Nepojmenovaný_přechod_65"
                        x1="734.07"
                        y1="370.98"
                        x2="714.92"
                        y2="243.06"
                        gradientTransform="translate(1243.91 779.35) rotate(180)"
                        xlink:href="#Nepojmenovaný_přechod_82"
                    />
                    <linearGradient
                        id="Nepojmenovaný_přechod_86-2"
                        x1="427.6"
                        y1="315.5"
                        x2="764.15"
                        y2="315.5"
                        gradientTransform="translate(1243.91 779.35) rotate(180)"
                        xlink:href="#Nepojmenovaný_přechod_86"
                    />
                    <linearGradient
                        id="Nepojmenovaný_přechod_83-6"
                        x1="675.79"
                        y1="152.42"
                        x2="675.79"
                        y2="152.42"
                        gradientTransform="translate(1243.91 779.35) rotate(180)"
                        xlink:href="#Nepojmenovaný_přechod_83"
                    />
                </defs>
                <title>logo</title>
                <g id="Vrstva_5" data-name="Vrstva 5">
                    <path
                        d="M574.12,322.21q-4.2-2.23-8-4.77C561,314,550.19,306.8,544.59,295.66c-7.5-14.94-1.52-28.44,1-34.2,8-17.94,25.36-28.32,33.72-33.31a146.14,146.14,0,0,1,71.06-20.57c11.59-.37,35.71-1.14,58.19,9.66,5.15,2.47,18.06,9.58,26.81,22.17,7.81,11.23,8.64,25.38,8.54,23.41a160,160,0,0,0,.87-16.32A155.54,155.54,0,0,0,727,174.07c-34.24-11.68-66.52-10.67-79.91-10.24-27.7.88-69.24,7-109.81,31.21-8.58,5.12-27.93,15.88-42.39,35.48C506.52,271.34,535.65,305.65,574.12,322.21Z"
                        transform="translate(-477.68 -163.6)"
                        fill="url(#Nepojmenovaný_přechod_83)"
                    />
                    <path
                        d="M750.34,259.6"
                        transform="translate(-477.68 -163.6)"
                        fill="url(#Nepojmenovaný_přechod_83-2)"
                    />
                    <path
                        d="M744.77,246.62a155.52,155.52,0,0,1-.84,16.23,39.58,39.58,0,0,1-.08,5.4L734.15,352a7.6,7.6,0,0,0,2.82,6.53,16.17,16.17,0,0,0,9.82,3.68l36.18,2.1a17.91,17.91,0,0,0,10.43-2.44,8.73,8.73,0,0,0,4.39-6.18l9.72-83.95,0-.4c1.08-12.11-.53-30.69-14.29-50.5-14.78-21.27-36.41-33.77-48-39.33A162.63,162.63,0,0,0,726.87,174,155.32,155.32,0,0,1,744.77,246.62Z"
                        transform="translate(-477.68 -163.6)"
                        fill="url(#Nepojmenovaný_přechod_82)"
                    />
                    <path
                        d="M806.92,408,700.3,368.54v0l-85.4-31.68c-.36-.09-.71-.19-1.06-.31l-21.52-8-1-.34A113.07,113.07,0,0,1,576,321.75a140.85,140.85,0,0,1-81-91.3,90.21,90.21,0,0,0-9.86,16.85c-3.61,8.14-14.6,32.92-.29,61.38,10.6,21.1,30.17,34.17,37.53,39.1a175.06,175.06,0,0,0,40.25,19.62l214.16,79.2c6.73,2.49,15.09.78,18.69-3.83l17.14-22C816.19,416.21,813.65,410.46,806.92,408Z"
                        transform="translate(-477.68 -163.6)"
                        fill="url(#Nepojmenovaný_přechod_86)"
                    />
                    <path
                        d="M725.87,176"
                        transform="translate(-477.68 -163.6)"
                        fill="url(#Nepojmenovaný_přechod_83-3)"
                    />
                    <path
                        d="M719.87,480.73a92.36,92.36,0,0,1,8,4.78c5.17,3.45,15.93,10.64,21.52,21.78,7.51,14.93,1.52,28.43-1,34.19-8,17.94-25.36,28.32-33.72,33.31a146,146,0,0,1-71.06,20.57c-11.59.37-35.72,1.14-58.19-9.66-5.15-2.47-18.06-9.57-26.82-22.17-7.8-11.23-8.63-25.38-8.53-23.4a159.68,159.68,0,0,0-.88,16.31A155.52,155.52,0,0,0,567,628.87c34.23,11.68,66.52,10.67,79.91,10.24,27.69-.87,69.24-7,109.81-31.21,8.57-5.11,27.93-15.88,42.39-35.48C787.47,531.6,758.34,497.29,719.87,480.73Z"
                        transform="translate(-477.68 -163.6)"
                        fill="url(#Nepojmenovaný_přechod_83-4)"
                    />
                    <path
                        d="M543.65,543.34"
                        transform="translate(-477.68 -163.6)"
                        fill="url(#Nepojmenovaný_přechod_83-5)"
                    />
                    <path
                        d="M549.22,556.33a158.36,158.36,0,0,1,.83-16.24,41.29,41.29,0,0,1,.08-5.4l9.7-83.73a7.6,7.6,0,0,0-2.82-6.53,16.17,16.17,0,0,0-9.82-3.68L511,438.65a17.91,17.91,0,0,0-10.43,2.44,8.73,8.73,0,0,0-4.39,6.18l-9.72,84,0,.4c-1.08,12.11.53,30.69,14.29,50.5,14.78,21.27,36.41,33.77,48,39.33a164,164,0,0,0,18.42,7.48A155.26,155.26,0,0,1,549.22,556.33Z"
                        transform="translate(-477.68 -163.6)"
                        fill="url(#Nepojmenovaný_přechod_65)"
                    />
                    <path
                        d="M487.07,395,593.68,434.4v0l85.4,31.68a9.93,9.93,0,0,1,1.06.32l21.52,8,1,.34a112.1,112.1,0,0,1,10.46,4.13c3.1,1.42,6.19,2.88,9.29,4.31A140.8,140.8,0,0,1,799,572.5a89.76,89.76,0,0,0,9.86-16.85c3.62-8.15,14.61-32.93.3-61.39-10.6-21.1-30.17-34.17-37.53-39.09a174.88,174.88,0,0,0-40.25-19.63l-214.16-79.2c-6.73-2.49-15.1-.77-18.69,3.83l-17.14,22C477.8,386.73,480.34,392.48,487.07,395Z"
                        transform="translate(-477.68 -163.6)"
                        fill="url(#Nepojmenovaný_přechod_86-2)"
                    />
                    <path
                        d="M568.12,626.93"
                        transform="translate(-477.68 -163.6)"
                        fill="url(#Nepojmenovaný_přechod_83-6)"
                    />
                </g>
            </svg>
            <p class="footer-links">
                <router-link to="/">O mne</router-link> ·
                <router-link to="/projects">Projeky</router-link> ·
                <router-link to="/contact">Kontakt</router-link>
            </p>
            <p class="footer-company-name footer-shadow">
                Bratislava ©
                {{ year }}
            </p>
        </div>
        <div class="footer-center">
            <div>
                <i class="fa fa-map-marker" aria-hidden="true"></i>
                <p><span>Bratislava</span> Slovensko, SK</p>
            </div>
            <div>
                <i class="fa fa-phone" aria-hidden="true"></i>
                <p href="tel:+421944050290">+421 944 050 290</p>
            </div>
            <div>
                <i class="fa fa-envelope" aria-hidden="true"></i>
                <p>
                    <a href="mailto:sebkoses@gmail.com+"
                        >mail@sebastian-kendra.sk</a
                    >
                </p>
            </div>
        </div>
        <div class="footer-right">
            <p class="footer-company-about">
                <span>Sebastián Kendra</span>
            </p>
            <p class="footer-shadow">Nájdete ma aj na</p>
            <div class="footer-icons">
                <a href="https://www.facebook.com/SebkOo"
                    ><i class="fa fa-facebook" aria-hidden="true"></i
                ></a>
                <a href="https://twitter.com/KendraSebastian"
                    ><i class="fa fa-twitter" aria-hidden="true"></i
                ></a>
                <a
                    href="https://www.linkedin.com/in/sebastian-kendra-b91b36140/"
                    ><i class="fa fa-linkedin" aria-hidden="true"></i
                ></a>
                <a href="https://github.com/Sebastian-Kendra"
                    ><i class="fa fa-github" aria-hidden="true"></i
                ></a>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    data() {
        return {
            year: new Date().getFullYear(),
        }
    },
}
</script>

<style lang="scss" scoped></style>
