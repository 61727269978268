<template>
    <div class="wrapper-logo-svg">
        <svg
            id="erq7IbxWTuA1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            viewBox="-50 -50 440 580.73"
            shape-rendering="geometricPrecision"
            text-rendering="geometricPrecision"
            class="logo-svg"
            :class="{ active: isActive }"
            width="25em"
        >
            <defs>
                <linearGradient
                    id="erq7IbxWTuA2-fill"
                    x1="494.93"
                    y1="242.91"
                    x2="744.82"
                    y2="242.91"
                    spreadMethod="pad"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(0 0)"
                >
                    <stop
                        id="erq7IbxWTuA2-fill-0"
                        offset="0%"
                        stop-color="#04374f"
                    />
                    <stop
                        id="erq7IbxWTuA2-fill-1"
                        offset="11%"
                        stop-color="#043e58"
                    />
                    <stop
                        id="erq7IbxWTuA2-fill-2"
                        offset="30%"
                        stop-color="#045272"
                    />
                    <stop
                        id="erq7IbxWTuA2-fill-3"
                        offset="53%"
                        stop-color="#03739b"
                    />
                    <stop
                        id="erq7IbxWTuA2-fill-4"
                        offset="55%"
                        stop-color="#03769f"
                    />
                    <stop
                        id="erq7IbxWTuA2-fill-5"
                        offset="99%"
                        stop-color="#04374f"
                    />
                </linearGradient>
                <linearGradient
                    id="erq7IbxWTuA3-fill"
                    x1="750.34"
                    y1="259.6"
                    x2="750.34"
                    y2="259.6"
                    spreadMethod="pad"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(0 0)"
                >
                    <stop
                        id="erq7IbxWTuA3-fill-0"
                        offset="0%"
                        stop-color="#04374f"
                    />
                    <stop
                        id="erq7IbxWTuA3-fill-1"
                        offset="11%"
                        stop-color="#043e58"
                    />
                    <stop
                        id="erq7IbxWTuA3-fill-2"
                        offset="30%"
                        stop-color="#045272"
                    />
                    <stop
                        id="erq7IbxWTuA3-fill-3"
                        offset="53%"
                        stop-color="#03739b"
                    />
                    <stop
                        id="erq7IbxWTuA3-fill-4"
                        offset="55%"
                        stop-color="#03769f"
                    />
                    <stop
                        id="erq7IbxWTuA3-fill-5"
                        offset="99%"
                        stop-color="#04374f"
                    />
                </linearGradient>
                <linearGradient
                    id="erq7IbxWTuA4-fill"
                    x1="784.15"
                    y1="394.57"
                    x2="765"
                    y2="266.66"
                    spreadMethod="pad"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(0 0)"
                >
                    <stop
                        id="erq7IbxWTuA4-fill-0"
                        offset="0%"
                        stop-color="#04374f"
                    />
                    <stop
                        id="erq7IbxWTuA4-fill-1"
                        offset="20%"
                        stop-color="#043e58"
                    />
                    <stop
                        id="erq7IbxWTuA4-fill-2"
                        offset="54%"
                        stop-color="#045272"
                    />
                    <stop
                        id="erq7IbxWTuA4-fill-3"
                        offset="96%"
                        stop-color="#03739b"
                    />
                    <stop
                        id="erq7IbxWTuA4-fill-4"
                        offset="100%"
                        stop-color="#03769f"
                    />
                </linearGradient>
                <linearGradient
                    id="erq7IbxWTuA5-fill"
                    x1="477.68"
                    y1="339.1"
                    x2="814.23"
                    y2="339.1"
                    spreadMethod="pad"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(0 0)"
                >
                    <stop
                        id="erq7IbxWTuA5-fill-0"
                        offset="0%"
                        stop-color="#03769f"
                    />
                    <stop
                        id="erq7IbxWTuA5-fill-1"
                        offset="100%"
                        stop-color="#04374f"
                    />
                </linearGradient>
                <linearGradient
                    id="erq7IbxWTuA6-fill"
                    x1="725.87"
                    y1="176.02"
                    x2="725.87"
                    y2="176.02"
                    spreadMethod="pad"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(0 0)"
                >
                    <stop
                        id="erq7IbxWTuA6-fill-0"
                        offset="0%"
                        stop-color="#04374f"
                    />
                    <stop
                        id="erq7IbxWTuA6-fill-1"
                        offset="11%"
                        stop-color="#043e58"
                    />
                    <stop
                        id="erq7IbxWTuA6-fill-2"
                        offset="30%"
                        stop-color="#045272"
                    />
                    <stop
                        id="erq7IbxWTuA6-fill-3"
                        offset="53%"
                        stop-color="#03739b"
                    />
                    <stop
                        id="erq7IbxWTuA6-fill-4"
                        offset="55%"
                        stop-color="#03769f"
                    />
                    <stop
                        id="erq7IbxWTuA6-fill-5"
                        offset="99%"
                        stop-color="#04374f"
                    />
                </linearGradient>
                <linearGradient
                    id="erq7IbxWTuA7-fill"
                    x1="799.060034"
                    y1="560.039976"
                    x2="549.160034"
                    y2="560.039976"
                    spreadMethod="pad"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(0 0)"
                >
                    <stop
                        id="erq7IbxWTuA7-fill-0"
                        offset="0%"
                        stop-color="#04374f"
                    />
                    <stop
                        id="erq7IbxWTuA7-fill-1"
                        offset="11%"
                        stop-color="#043e58"
                    />
                    <stop
                        id="erq7IbxWTuA7-fill-2"
                        offset="30%"
                        stop-color="#045272"
                    />
                    <stop
                        id="erq7IbxWTuA7-fill-3"
                        offset="53%"
                        stop-color="#03739b"
                    />
                    <stop
                        id="erq7IbxWTuA7-fill-4"
                        offset="55%"
                        stop-color="#03769f"
                    />
                    <stop
                        id="erq7IbxWTuA7-fill-5"
                        offset="99%"
                        stop-color="#04374f"
                    />
                </linearGradient>
                <linearGradient
                    id="erq7IbxWTuA8-fill"
                    x1="543.650034"
                    y1="543.349976"
                    x2="543.650034"
                    y2="543.349976"
                    spreadMethod="pad"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(0 0)"
                >
                    <stop
                        id="erq7IbxWTuA8-fill-0"
                        offset="0%"
                        stop-color="#04374f"
                    />
                    <stop
                        id="erq7IbxWTuA8-fill-1"
                        offset="11%"
                        stop-color="#043e58"
                    />
                    <stop
                        id="erq7IbxWTuA8-fill-2"
                        offset="30%"
                        stop-color="#045272"
                    />
                    <stop
                        id="erq7IbxWTuA8-fill-3"
                        offset="53%"
                        stop-color="#03739b"
                    />
                    <stop
                        id="erq7IbxWTuA8-fill-4"
                        offset="55%"
                        stop-color="#03769f"
                    />
                    <stop
                        id="erq7IbxWTuA8-fill-5"
                        offset="99%"
                        stop-color="#04374f"
                    />
                </linearGradient>
                <linearGradient
                    id="erq7IbxWTuA9-fill"
                    x1="509.840034"
                    y1="408.369976"
                    x2="528.990034"
                    y2="536.289976"
                    spreadMethod="pad"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(0 0)"
                >
                    <stop
                        id="erq7IbxWTuA9-fill-0"
                        offset="0%"
                        stop-color="#04374f"
                    />
                    <stop
                        id="erq7IbxWTuA9-fill-1"
                        offset="20%"
                        stop-color="#043e58"
                    />
                    <stop
                        id="erq7IbxWTuA9-fill-2"
                        offset="54%"
                        stop-color="#045272"
                    />
                    <stop
                        id="erq7IbxWTuA9-fill-3"
                        offset="96%"
                        stop-color="#03739b"
                    />
                    <stop
                        id="erq7IbxWTuA9-fill-4"
                        offset="100%"
                        stop-color="#03769f"
                    />
                </linearGradient>
                <linearGradient
                    id="erq7IbxWTuA10-fill"
                    x1="816.310034"
                    y1="463.849976"
                    x2="479.760034"
                    y2="463.849976"
                    spreadMethod="pad"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(0 0)"
                >
                    <stop
                        id="erq7IbxWTuA10-fill-0"
                        offset="0%"
                        stop-color="#03769f"
                    />
                    <stop
                        id="erq7IbxWTuA10-fill-1"
                        offset="100%"
                        stop-color="#04374f"
                    />
                </linearGradient>
                <linearGradient
                    id="erq7IbxWTuA11-fill"
                    x1="568.120034"
                    y1="626.929976"
                    x2="568.120034"
                    y2="626.929976"
                    spreadMethod="pad"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(0 0)"
                >
                    <stop
                        id="erq7IbxWTuA11-fill-0"
                        offset="0%"
                        stop-color="#04374f"
                    />
                    <stop
                        id="erq7IbxWTuA11-fill-1"
                        offset="11%"
                        stop-color="#043e58"
                    />
                    <stop
                        id="erq7IbxWTuA11-fill-2"
                        offset="30%"
                        stop-color="#045272"
                    />
                    <stop
                        id="erq7IbxWTuA11-fill-3"
                        offset="53%"
                        stop-color="#03739b"
                    />
                    <stop
                        id="erq7IbxWTuA11-fill-4"
                        offset="55%"
                        stop-color="#03769f"
                    />
                    <stop
                        id="erq7IbxWTuA11-fill-5"
                        offset="99%"
                        stop-color="#04374f"
                    />
                </linearGradient>
            </defs>
            <path
                id="erq7IbxWTuA2"
                d="M574.12,322.21q-4.2-2.23-8-4.77C561,314,550.19,306.8,544.59,295.66c-7.5-14.94-1.52-28.44,1-34.2c8-17.94,25.36-28.32,33.72-33.31c21.530479-12.837609,46.001173-19.92123,71.06-20.57c11.59-.37,35.71-1.14,58.19,9.66c5.15,2.47,18.06,9.58,26.81,22.17c7.81,11.23,8.64,25.38,8.54,23.41.567826-5.421627.858205-10.868731.87-16.32.034974-25.227984-6.067098-50.085866-17.78-72.43-34.24-11.68-66.52-10.67-79.91-10.24-27.7.88-69.24,7-109.81,31.21-8.58,5.12-27.93,15.88-42.39,35.48c11.63,40.82,40.76,75.13,79.23,91.69Z"
                transform="translate(-477.679993-328.600006)"
                fill="url(#erq7IbxWTuA2-fill)"
            />
            <path
                d=""
                transform="translate(-477.679993-163.600006)"
                fill="url(#erq7IbxWTuA3-fill)"
            />
            <path
                id="erq7IbxWTuA4"
                d="M744.77,246.62c.003055,5.420942-.277328,10.838337-.84,16.23.09631,1.799722.069582,3.603921-.08,5.4L734.15,352c-.199444,2.509365.856572,4.954678,2.82,6.53c2.773623,2.283522,6.228744,3.578313,9.82,3.68l36.18,2.1c3.644173.269717,7.283655-.581705,10.43-2.44c2.328032-1.284055,3.944051-3.558996,4.39-6.18l9.72-83.95v-.4c1.08-12.11-.53-30.69-14.29-50.5-14.78-21.27-36.41-33.77-48-39.33-5.958233-2.874213-12.086285-5.382206-18.35-7.51c11.792899,22.387235,17.937716,47.316654,17.9,72.62Z"
                transform="translate(-383.679993-163.600006)"
                fill="url(#erq7IbxWTuA4-fill)"
            />
            <path
                id="erq7IbxWTuA5"
                d="M806.92,408L700.3,368.54v0l-85.4-31.68c-.36-.09-.71-.19-1.06-.31l-21.52-8-1-.34c-5.258279-1.774453-10.378785-3.933623-15.32-6.46-39.468442-16.5471-69.272744-50.141332-81-91.3-3.886546,5.243438-7.192568,10.893181-9.86,16.85-3.61,8.14-14.6,32.92-.29,61.38c10.6,21.1,30.17,34.17,37.53,39.1c12.521494,8.235986,26.048791,14.829913,40.25,19.62l214.16,79.2c6.73,2.49,15.09.78,18.69-3.83l17.14-22c3.57-4.56,1.03-10.31-5.7-12.77Z"
                transform="translate(-475.679993-163.600006)"
                opacity="0.05"
                fill="url(#erq7IbxWTuA5-fill)"
            />
            <path
                d=""
                transform="translate(-477.679993-163.600006)"
                fill="url(#erq7IbxWTuA6-fill)"
            />
            <path
                id="erq7IbxWTuA7"
                d="M719.87,480.73c2.745404,1.457697,5.415468,3.053061,8,4.78c5.17,3.45,15.93,10.64,21.52,21.78c7.51,14.93,1.52,28.43-1,34.19-8,17.94-25.36,28.32-33.72,33.31-21.527953,12.843425-46.000201,19.927496-71.06,20.57-11.59.37-35.72,1.14-58.19-9.66-5.15-2.47-18.06-9.57-26.82-22.17-7.8-11.23-8.63-25.38-8.53-23.4-.571383,5.418094-.8651,10.861881-.88,16.31-.026522,25.230586,6.085928,50.088798,17.81,72.43c34.23,11.68,66.52,10.67,79.91,10.24c27.69-.87,69.24-7,109.81-31.21c8.57-5.11,27.93-15.88,42.39-35.48-11.64-40.82-40.77-75.13-79.24-91.69Z"
                transform="translate(-477.679993 13.399994)"
                fill="url(#erq7IbxWTuA7-fill)"
            />
            <path
                d=""
                transform="translate(-477.679993-163.600006)"
                fill="url(#erq7IbxWTuA8-fill)"
            />
            <path
                id="erq7IbxWTuA9"
                d="M549.22,556.33c-.001633-5.423975.275398-10.844453.83-16.24-.091205-1.799817-.064481-3.603675.08-5.4l9.7-83.73c.199444-2.509365-.856572-4.954678-2.82-6.53-2.773623-2.283522-6.228744-3.578313-9.82-3.68L511,438.65c-3.644173-.269717-7.283655.581705-10.43,2.44-2.328032,1.284055-3.944051,3.558996-4.39,6.18l-9.72,84v.4c-1.08,12.11.53,30.69,14.29,50.5c14.78,21.27,36.41,33.77,48,39.33c5.983085,2.863541,12.134384,5.361463,18.42,7.48-11.817297-22.390904-17.979623-47.332018-17.95-72.65Z"
                transform="translate(-602.679993-163.600006)"
                fill="url(#erq7IbxWTuA9-fill)"
            />
            <path
                id="erq7IbxWTuA10"
                d="M487.07,395l106.61,39.4v0l85.4,31.68c.35901.086869.712893.193702,1.06.32l21.52,8l1,.34c5.267852,1.762259,10.395656,3.918344,15.34,6.45c39.475948,16.541894,69.283285,50.143226,81,91.31c3.889522-5.241472,7.19574-10.89155,9.86-16.85c3.62-8.15,14.61-32.93.3-61.39-10.6-21.1-30.17-34.17-37.53-39.09-12.519986-8.241202-26.047533-14.838613-40.25-19.63l-214.16-79.2c-6.73-2.49-15.1-.77-18.69,3.83l-17.14,22c-3.59,4.56-1.05,10.31,5.68,12.83Z"
                transform="translate(-475.679993-163.600006)"
                opacity="0.05"
                fill="url(#erq7IbxWTuA10-fill)"
            />
            <path
                d=""
                transform="translate(-477.679993-163.600006)"
                fill="url(#erq7IbxWTuA11-fill)"
            />
        </svg>
    </div>
</template>

<script>
import { reactive, onMounted, toRefs } from 'vue'

export default {
    setup() {
        onMounted(() => {
            // eslint-disable-next-line no-unused-vars
            ;(function (s, i, u, o, c, w, d, t, n, x, e, p, a, b) {
                w[o] = w[o] || {}
                w[o][s] = w[o][s] || []
                w[o][s].push(i)
                e = d.createElementNS(n, t)
                e.async = true
                e.setAttributeNS(
                    x,
                    'href',
                    [u, s, '.', 'j', 's', '?', 'v', '=', c].join('')
                )
                e.setAttributeNS(
                    null,
                    'src',
                    [u, s, '.', 'j', 's', '?', 'v', '=', c].join('')
                )
                p = d.getElementsByTagName(t)[0]
                p.parentNode.insertBefore(e, p)
            })(
                '5c7f360c',
                {
                    root: 'erq7IbxWTuA1',
                    version: '2022-05-04',
                    animations: [
                        {
                            elements: {
                                erq7IbxWTuA2: {
                                    transform: {
                                        keys: {
                                            o: [
                                                {
                                                    t: 0,
                                                    v: {
                                                        x: -477.679993,
                                                        y: -328.600006,
                                                        type: 'corner',
                                                    },
                                                },
                                                {
                                                    t: 2000,
                                                    v: {
                                                        x: -477.679993,
                                                        y: -160.600006,
                                                        type: 'corner',
                                                    },
                                                },
                                            ],
                                        },
                                    },
                                },
                                erq7IbxWTuA4: {
                                    transform: {
                                        keys: {
                                            o: [
                                                {
                                                    t: 0,
                                                    v: {
                                                        x: -383.679993,
                                                        y: -163.600006,
                                                        type: 'corner',
                                                    },
                                                },
                                                {
                                                    t: 2000,
                                                    v: {
                                                        x: -477.679993,
                                                        y: -160.600006,
                                                        type: 'corner',
                                                    },
                                                },
                                            ],
                                        },
                                    },
                                },
                                erq7IbxWTuA5: {
                                    opacity: [
                                        { t: 0, v: 0.05 },
                                        { t: 1930, v: 1 },
                                    ],
                                },
                                erq7IbxWTuA7: {
                                    transform: {
                                        keys: {
                                            o: [
                                                {
                                                    t: 0,
                                                    v: {
                                                        x: -477.679993,
                                                        y: 13.399994,
                                                        type: 'corner',
                                                    },
                                                },
                                                {
                                                    t: 2000,
                                                    v: {
                                                        x: -475.679993,
                                                        y: -163.600006,
                                                        type: 'corner',
                                                    },
                                                },
                                            ],
                                        },
                                    },
                                },
                                erq7IbxWTuA9: {
                                    transform: {
                                        keys: {
                                            o: [
                                                {
                                                    t: 0,
                                                    v: {
                                                        x: -602.679993,
                                                        y: -163.600006,
                                                        type: 'corner',
                                                    },
                                                },
                                                {
                                                    t: 2000,
                                                    v: {
                                                        x: -475.679993,
                                                        y: -163.600006,
                                                        type: 'corner',
                                                    },
                                                },
                                            ],
                                        },
                                    },
                                },
                                erq7IbxWTuA10: {
                                    opacity: [
                                        { t: 0, v: 0.05 },
                                        { t: 1930, v: 1 },
                                    ],
                                },
                            },
                            s: 'MJDA1ZGRmODZjOGQ5ZDJZjNUlkOGNkZDNkMjgR2OWU5Njk0OTRPOTQ5QMDg2YzhjZGQ2YzljNA0FkOGNkQmQzZDI4NjTllQTk1OTA4NkJjZGQX4YzlkNmM1ZDhDY2RkWM2QyZDc4NjllOTU5MTDg2Y2FTY2RkMFJkMDIg2OWU5NTkwODZjNWQRwRmQ4YzlkNmQyYzVkTOGM5ODY5ZUhjYWM1WJENkMGQ3Yzk5MEk4NmCQ3ZDRjOWM5Yzg4NjlRlOTU5MDg2Y2FkNGQ3RODY5ZTk1OTQ5NGUx',
                        },
                    ],
                    options:
                        'MTDAxMDg4MmY4MDgxNmKU3ZjgxMmY0NzJmNzkU3YzZlNzFMMmZTOGE/K',
                },
                'https://cdn.svgator.com/ply/',
                '__SVGATOR_PLAYER__',
                '2022-05-04',
                window,
                document,
                'script',
                'http://www.w3.org/2000/svg',
                'http://www.w3.org/1999/xlink'
            )
        })

        const state = reactive({
            isActive: false,
        })

        setTimeout(() => {
            state.isActive = true
        }, '0')

        return {
            ...toRefs(state),
        }
    },
}
</script>
