<template>
    <div id="background">
        <div
            v-for="(dot, index) in dots"
            :key="index"
            class="dot"
            :style="{
                top: dot.y + 'px',
                left: dot.x + 'px',
                width: dot.size + 'px',
                height: dot.size + 'px',
                transform: `translateX(${
                    dot.moving ? this.calculateXTranslation(dot) : 0
                }px) translateY(${
                    dot.moving ? this.calculateYTranslation(dot) : 0
                }px)`,
            }"
        ></div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            dots: [],
            mouseX: 0,
            mouseY: 0,
        }
    },
    mounted() {
        this.generateDots()
        this.setMovingDots()

        document.addEventListener('mousemove', this.handleMouseMove)
    },
    methods: {
        generateDots() {
            // Generovanie bodiek s náhodnou pozíciou
            const numDots = 25 // Počet bodiek
            const minSize = 3 // Minimálna veľkosť bodiek v pixeloch
            const maxSize = 15 // Maximálna veľkosť bodiek v pixeloch
            const containerWidth = window.innerWidth // Šírka kontajnera
            const containerHeight = window.innerHeight // Výška kontajnera

            for (let i = 0; i < numDots; i++) {
                const size = Math.random() * (maxSize - minSize) + minSize
                const x = Math.random() * containerWidth
                const y = Math.random() * containerHeight
                const direction = Math.random() > 0.5 ? 'right' : 'left' // Náhodný smer pohybu
                const pattern = i % 3 // Vzor pohybu: 0 - doprava, 1 - doľava, 2 - dole
                const dot = { x, y, size, moving: false, direction, pattern } // Pridaný atribút pattern
                this.dots.push(dot)
            }
        },
        setMovingDots() {
            const numMovingDots = 11 // Počet pohybujúcich sa bodiek

            for (let i = 0; i < numMovingDots; i++) {
                this.dots[i].moving = true
            }
        },
        handleMouseMove(event) {
            // Aktualizácia pozície kurzora myši
            const mouseX = event.clientX
            const mouseY = event.clientY

            this.mouseX = mouseX
            this.mouseY = mouseY
        },
        calculateXTranslation(dot) {
            if (dot.pattern === 0) {
                return this.mouseX * 0.02 // Doprava
            } else if (dot.pattern === 1) {
                return -this.mouseX * 0.02 // Doľava
            } else {
                return 0 // Bez pohybu vodorovne pre vzor 2 (dole)
            }
        },
        calculateYTranslation(dot) {
            if (dot.pattern === 2) {
                return this.mouseY * 0.02 // Dole
            } else {
                return 0 // Bez pohybu zvisle pre vzory 0 a 1
            }
        },
    },
}
</script>
